import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import images from "../libs/images";
import * as api from "../libs/api";
import { imageUrl } from "../libs/utils";
import Pagination from 'react-js-pagination';
import BtnFileDownload from '../components/file_download';
import FileDownload from '../components/file_download';


export default function Update(props) {
    const [list, setList] = useState([]);
    let [school, setSchool] = useState("");
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);

    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    
    const onSchool =(txt) => {

        onList(1,txt);
    }
    
    const onList = (page,ss) => {        
        if(ss){
            school = ss;
        }
        
        api.getData("/update" ,{
            page:page-1,
            size:limit,
            school:(school == 0 ? "" : school)
        }).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setSchool(school);
                const ret = res.data.data;
                setList(ret.content);
                setPage(page-1);
                setTotal(ret.totalElements);                   
            }
        });
    }
    function goUrl(url){
        if(url.indexOf("http")>=0){
            window.open(url);
        }else{
            navigate(url);
        }
    }
    function onNewCheck(date){
        var tDate = new Date();
        tDate.setMonth(tDate.getMonth()-1);
        var cDate = new Date(date)
        let dtext = "";

        if( tDate < cDate ){
            dtext = "[NEW] ";
        }
       
        return dtext;
    }
    
    useEffect(() => {
        if(id == 2){
            onSchool('고등');
        }else if(id == 1){
            onSchool('중학');
        } else {
            onSchool("0");
        }
    }, []);
    return (
        <div className="changbi">
            <div className="wrap">
                <div className="my">
                    <h1 className="title">업데이트 자료</h1>
                    <div className="uptag">
                        <button type="button" className={school == '0' ? 'u01 active' : 'u01'} onClick={(e)=>onSchool("0")}>전체</button>
                        <button type="button" className={school == '중학' ? 'u02 active' : 'u02'} onClick={(e)=>onSchool("중학")}>중학</button>
                        <button type="button" className={school == '고등' ? 'u03 active' : 'u03'} onClick={(e)=>onSchool("고등")}>고등</button>
                    </div>
                    <ul className="update">
                        {list.map(val=>
                        <li >
                            <span  className={val.school == '중학' ? 'u02' : (val.school == '고등' ? 'u03' : '')}>{val.school}</span>
                            <div>
                               
                                {val.link ? 
                                <h2 onClick={()=>navigate(val.link)}><span>{onNewCheck(val.registDate)}</span>{val.title}</h2>
                                :
                                <h2 ><span>{onNewCheck(val.registDate)}</span>{val.title}</h2>
                                }
                                <p>{val.memo}</p>
                                {val?.file && (<BtnFileDownload  folder="board" filename={val?.file} filename_original={val?.fileOri}/>)}

                            </div>
                        </li>
                        )}
                        {list.length > 0 ? <></> : <li >등록된 자료가 없습니다.</li> }
                    </ul>
                    {list.length > 0 ? 
                    <div className="paging">
                        <Pagination
                            activePage={page+1}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>             
                    : <></>       
                    }
                </div>
            </div>
        </div>
    );
}
